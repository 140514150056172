<template>
  <div class="contact_us">
    <h1>Get in touch</h1>
    <p class="my-3">We would love to hear from you.</p>
    <ContactForm />
  </div>
</template>

<script>
import ContactForm from '@/components/form/ContactForm.vue';

export default {
  name: 'ContactUsPage',
  components: { ContactForm },
};
</script>
